<template>
  <div class="tab">
    <p class="tab-title">
      Subscribers who are set to inactive may keep their Klubba card but it will appear as inactive
      and should not be used to access services.
    </p>

    <!-- Active -->
    <label class="tab-checkbox">
      <input type="checkbox" v-model="active" />
      <div class="tab-checkbox-slider" />
      <span>Customer is {{ active ? "active" : "inactive" }}</span>
    </label>

    <div class="tab-divider" />

    <!-- Options -->
    <div class="tab-radio">
      <label v-for="(option, index) in options" :key="`option-${index}`" class="tab-radio-input">
        <input :disabled="true /* active */" type="radio" :value="option.value" v-model="refund" />
        <div class="tab-radio-slider" />
        <span>{{ option.text }}</span>
      </label>
    </div>

    <div class="tab-divider" />

    <!-- Message checkbox -->
    <label class="tab-checkbox">
      <input type="checkbox" v-model="showMessage" />
      <div class="tab-checkbox-slider" />
      <span>Send {{ active ? "confirmation" : "termination" }} message</span>
    </label>

    <!-- Message -->
    <div v-if="showMessage" class="message">
      <div class="tab-show-message" @click="showTags = !showTags">
        <span>{{ showTags ? "Hide" : "Show" }} message substitutions</span>
      </div>
      <div v-if="showTags" class="tab-tags">
        <span v-for="(tag, tagIndex) in smartTags" :key="tag">
          <span @click="message += tag" class="smart-tag">{{ tag }}</span>
          <span>{{ tagIndex !== smartTags.length - 1 ? ", " : "" }}</span>
        </span>
      </div>
      <div class="tab-textarea">
        <textarea v-model="message" :maxlength="maxLength" placeholder="Type over this" />
        <span>Message</span>
        <div class="tab-textarea-count">{{ message.length }} / {{ maxLength }}</div>
      </div>
      <div v-if="formattedMessageExample.length > 0" class="result">
        <div class="message-example-title">Message example:</div>
        <div class="message-example">{{ formattedMessageExample }}</div>
      </div>
    </div>

    <!-- Button -->
    <div class="tab-button-wrapper">
      <BaseButton class="tab-button" :disabled="disableButton" @click="applyChange"
        >Apply changes</BaseButton
      >
    </div>
  </div>
</template>

<script>
import firebaseApp from "@/config/firebase";
import BaseButton from "../BaseButton";
import { mapGetters } from "vuex";
import firebase from "firebase";
import { ReplaceSmartTagsWithContent } from "./smartTags";
import { useToast } from "vue-toastification";

export default {
  name: "ChangeActive",
  components: { BaseButton },
  data() {
    const toast = useToast();
    return {
      toast: toast,
      active: true,
      refund: true,
      showMessage: false,
      showTags: false,
      message: "",
      maxLength: 160,
      options: [
        {
          text: "Refund unused credits",
          value: false,
        },
        {
          text: "Allow subscription to expire naturally",
          value: true,
        },
      ],
      smartTags: [
        "[KLUBBA_URL]",
        "[SUBSCRIBER_FIRSTNAME]",
        "[SUBSCRIBER_PRODUCT]",
        "[SUBSCRIPTION_EXPIRY_DATE]",
        "[SUBSCRIPTION_AMOUNT]",
        "[CLUB_NAME]",
        "[DAYS_UNTIL_EXPIRY]",
      ],
    };
  },
  computed: {
    ...mapGetters(["clubSubscriptionById", "clubId"]),
    formattedMessageExample() {
      return this.message.length > 0
        ? ReplaceSmartTagsWithContent(this, this.subscribers[0], this.message)
        : "";
    },
    disableButton() {
      return this.showMessage ? this.message.length < 10 : false;
    },
    users() {
      return this.subscribers.filter((subscriber) => subscriber?.subscription?.stripe_data?.id);
    },
  },
  props: {
    subscribers: {
      type: Array,
      required: true,
      default: () => [],
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getMessages(subscribers) {
      return subscribers.map((subscriber) => {
        const subscriptionId = subscriber?.subscription?.stripe_data?.subscription_id ?? "";

        return {
          datestamp: firebase.firestore.Timestamp.now().seconds * 1000,
          subscriber_id: subscriber.id,
          subscriber_firstname: subscriber?.first_name ?? "",
          subscriber_lastname: subscriber?.last_name ?? "",
          subscriber_phone: subscriber?.phone_number ?? "",
          subscriber_status: subscriber?.club_specific?.state ?? "inactive",
          subscription_id: subscriptionId,
          subscription_title: subscriber?.subscription?.product_name ?? "", // TODO: Add subscription title there
          subscription_fee: subscriber?.subscription?.price
            ? Number(subscriber?.subscription?.price)
            : "",
          subscription_expiry: subscriber?.subscription?.period?.end ?? "",
          message_status: "pending",
          message: ReplaceSmartTagsWithContent(this, subscriber, this.message),
        };
      });
    },
    async applyChange() {
      this.$emit("handleLoading", true);

      const data = {
        club_id: this.clubId,
      };

      if (this.active) {
        data.state = "active";
      } else {
        data.state = this.refund ? "pause_refund" : "pause_expire";
      }

      data.users =
        this.users?.filter((user) =>
          data.state === "active"
            ? user.club_specific.state === "paused"
            : user.club_specific.state === "active"
        ) ?? [];

      if (this.showMessage && this.message.length >= 10) {
        data.messages = this.getMessages([...data.users]);
      }

      data.users = data.users.map((subscriber) => ({
        user_id: subscriber.id,
        stripe_sub_id: subscriber.subscription.stripe_data.id,
      }));

      console.log(data);

      if (data.users.length > 0) {
        console.log("send");
        const changeUsersState = await firebaseApp.functions().httpsCallable("changeUsersState");
        await changeUsersState(data)
          .then((result) => {
            console.log(result);
            this.message = "";
            this.$emit("handleLoading", false);
          })
          .catch((error) => {
            console.log(error);
            this.toast.error(error.message);
            this.$emit("handleLoading", false);
          });
      } else {
        console.log("dont send");
        this.$emit("handleLoading", true);
        setTimeout(() => {
          this.message = "";
          this.$emit("handleLoading", false);
        }, 1000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tab {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
}

.tab-title {
  margin-bottom: 76px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.2px;
  color: #757f8c;
}

.tab-button-wrapper {
  margin-top: 60px;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
}

.tab-radio {
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  gap: 34px;
}

.tab-radio-input {
  padding-left: 12px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.tab-radio-input span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #111111;
  opacity: 0.48;
}

.tab-radio-slider {
  margin-right: 49px;
  transition: 0.4s;
  display: block;
  background: #f0f1f4;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  position: relative;
}

.tab-radio-slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f1f4;
  border-radius: 50%;
  transition: 0.4s;
}

.tab-radio-input input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.tab-radio-input input:checked + .tab-radio-slider {
  background-color: #0ba3a9;
}

.tab-radio-input input:focus + .tab-radio-slider {
  box-shadow: 0 0 1px #0ba3a9;
}

.tab-radio-input input:checked + .tab-radio-slider:before {
  background-color: #fff;
}

.tab-divider {
  background: #e8e9ec;
  height: 1px;
  width: 100%;
  margin-bottom: 29px;
  margin-top: 29px;
}

.tab-checkbox {
  cursor: pointer;
  display: flex;
  position: relative;
}

.tab-checkbox span {
  margin-left: 36px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #111111;
  opacity: 0.48;
}

.tab-checkbox-slider {
  background-color: #fff;
  border: 2px solid #f0f1f4;
  transition: 0.4s;
  display: block;
  width: 48px;
  height: 24px;
  border-radius: 100px;
}

.tab-checkbox-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: #f0f1f4;
  border-radius: 50%;
  transition: 0.4s;
}

.tab-checkbox input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.tab-checkbox input:checked + .tab-checkbox-slider {
  background-color: #0ba3a9;
  border-color: transparent;
}

.tab-checkbox input:focus + .tab-checkbox-slider {
  box-shadow: 0 0 1px #0ba3a9;
}

.tab-checkbox input:checked + .tab-checkbox-slider:before {
  transform: translateX(24px);
  background-color: #fff;
}

.message {
  margin-top: 20px;
}

.tab-show-message {
  cursor: pointer;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.2px;
  text-decoration: underline;
  color: #0ba3a9;
}

.result {
  margin-top: -20px;
}

.message-example-title {
  font-size: 12px;
  margin-bottom: 12px;
  color: #919397;
}

.tab-tags {
  margin-top: 12px;
  margin-bottom: 44px;
  padding: 22px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: rgba(17, 17, 17, 0.48);
  background: #f0f1f4;
  border-radius: 8px;
}

.message-example {
  padding: 10px 15px;
  border-radius: 5px;
  line-height: 1.5;
  font-size: 14px;
  background: #f0f1f4;
}

.tab-number input,
.tab-textarea textarea {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #323c47;
  border: 1px solid #a6aab4;
  border-radius: 6px;
  padding: 16px 20px;
  outline: none;
  resize: none;
}

.tab-number input:focus,
.tab-textarea textarea:focus {
  border-color: #0ba3a9;
}

.tab-number input:focus + span,
.tab-textarea textarea:focus + span {
  color: #0ba3a9;
}

.tab-number input::placeholder,
.tab-textarea textarea::placeholder {
  color: #a6aab4;
}

.tab-number,
.tab-textarea {
  position: relative;
}

.tab-number {
  margin-bottom: 8px;
}

.tab-number svg {
  position: absolute;
  left: 21px;
  top: 20px;
}

.tab-textarea textarea {
  margin-bottom: 6px;
  min-height: 128px;
}

.tab-number span,
.tab-textarea span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #a6aab4;
  position: absolute;
  top: -10px;
  background: #fff;
  padding: 0 8px;
  left: 14px;
}

.tab-textarea-count {
  margin-bottom: 37px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.2px;
  color: rgba(17, 17, 17, 0.48);
}

.tab-number input {
  padding-left: 38px;
}

.tab-number input::-webkit-inner-spin-button {
  display: none;
}

.smart-tag {
  transition: color 0.2s ease;
  cursor: pointer;

  &:hover {
    color: #0ba3a9;
  }
}
</style>
