<template>
  <div class="subscribers">
    <div class="subscribers-filter-box">
      <div class="subscribers-filter-box-search">
        <input type="text" v-model="filters.search" placeholder="Search" />
        <inline-svg :src="require('@/assets/icons/search.svg')" />
      </div>
      <div class="subscribers-filter-box-tabs">
        <div
          class="subscribers-filter-box-tabs-item"
          :class="{ 'is-active': filters.tags.includes('Latest') }"
          @click="toggleFilterTag('Latest')"
        >
          Latest
        </div>
        <div
          class="subscribers-filter-box-tabs-item"
          :class="{ 'is-active': filters.tags.includes('Unpaid') }"
          @click="toggleFilterTag('Unpaid')"
        >
          Unpaid
        </div>
        <div
          class="subscribers-filter-box-tabs-item"
          :class="{ 'is-active': filters.tags.includes('Inactive') }"
          @click="toggleFilterTag('Inactive')"
        >
          Inactive
        </div>
        <div
          class="subscribers-filter-box-tabs-item"
          :class="{ 'is-active': filters.tags.includes('Expiring soon') }"
          @click="toggleFilterTag('Expiring soon')"
        >
          Expiring soon
        </div>
      </div>
      <!-- <div
        class="subscribers-filter-box-button"
        @click="$router.push('/subscribers/create-way')"
      > -->
      <div class="subscribers-filter-box-button" @click="$router.push('/subscribers/create')">
        Create a subscriber
      </div>
    </div>

    <!-- Subscribers table -->
    <div class="subscribers-table" v-loading="isLoading">
      <el-empty v-if="computedSubscribers.length === 0" description="No subscribers yet" />
      <div v-if="computedSubscribers.length !== 0" class="subscribers-table-header">
        <label class="subscribers-table-checkbox">
          <input
            type="checkbox"
            :checked="selectAllCheckboxState"
            @change="handleSelectAllCheckbox"
          />
          <span>{{ selectAllCheckboxLabel }}</span>
          <span class="clear-all-text" v-if="selectAllCheckboxLabel === 'All selected'"
            >Clear all</span
          >
        </label>
        <div
          class="subscribers-table-apply"
          :class="{ disabled: selectedSubscribers.length === 0 }"
          @click="showApplyModal"
        >
          Apply an action
        </div>
      </div>

      <!-- Table rows -->
      <div v-if="computedSubscribers.length !== 0" class="subscribers-table-body">
        <div
          class="subscribers-table-row"
          :class="{
            'subscribers-table-row--selected': subscriber.selectedInTable,
          }"
          v-for="(subscriber, index) in computedSubscribers"
          :key="`subscriber-${index}`"
        >
          <div class="subscribers-table-row-select">
            <input
              type="checkbox"
              :checked="selectedSubscribersArray.includes(subscriber.id)"
              @change="handleSubscriberSelect(subscriber.id, $event.target.checked)"
            />
          </div>
          <div class="subscribers-table-row-name">
            <span @click="$router.push(`/subscribers/edit/${subscriber.id}`)">
              {{ subscriber.first_name }} {{ subscriber.last_name }}
            </span>
          </div>
          <div class="subscribers-table-row-email">
            {{ subscriber.email }}
          </div>
          <div class="subscribers-table-row-phone">
            {{ subscriber.phone_number }}
          </div>
          <div class="subscribers-table-row-level" v-if="clubSubscriptions.length > 0">
            {{ getSubscription(subscriber.subscription) }}
          </div>

          <!-- Tags -->
          <div class="subscribers-table-row-tags">
            <div
              v-for="(tag, index) in getSubscriberTags(subscriber) ?? []"
              :key="`subscriber-tag-${index}`"
              class="subscribers-table-row-tags-item"
              :class="`tag-color-${tag.color}`"
              @click="tag?.onClick ? tag.onClick() : () => {}"
            >
              {{ tag.text }}
            </div>
          </div>

          <!-- Joined -->
          <div v-if="subscriber.club_specific?.join_date" class="subscribers-table-row-date">
            Joined {{ moment(subscriber.club_specific?.join_date).fromNow() }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <router-view />
  <ApplyAction
    v-if="isApplyModalVisible"
    :selected-subscribers="selectedSubscribers"
    @closeModal="hideApplyModal"
  />
</template>
<script>
import InlineSvg from "vue-inline-svg";
import moment from "moment";
import ApplyAction from "@/components/ApplyAction/ApplyAction";
import { mapGetters } from "vuex";

export default {
  name: "Subscribers",
  components: {
    InlineSvg,
    ApplyAction,
  },
  data() {
    return {
      moment,
      isApplyModalVisible: false,
      isLoading: false,
      selectedSubscribersArray: [],
      filters: {
        search: "",
        tags: [],
      },
    };
  },
  computed: {
    ...mapGetters(["club", "clubId", "users", "clubSubscriptions"]),
    computedSubscribers() {
      let filteredSubscribers = this.users;

      const searchableFields = [
        "first_name",
        "last_name",
        "phone_number",
        "email",
        "club_specific.tags",
      ];
      const searchText = this.filters.search.toLowerCase().replaceAll(" ", "");
      const filterTags = this.filters.tags;
      const tagsToFilterBy = ["Unpaid", "Inactive", "Expiring soon"];

      // Search
      if (this.filters.search) {
        filteredSubscribers = filteredSubscribers.filter((subscriber) =>
          searchableFields
            .map((field) => subscriber[field])
            .join("")
            .toLowerCase()
            .includes(searchText)
        );
      }

      // Latest
      if (filterTags.includes("Latest")) {
        filteredSubscribers = [...filteredSubscribers].sort(
          (a, b) => (b?.club_specific?.join_date ?? 0) - (a?.club_specific?.join_date ?? 0)
        );
      }

      // Tags
      tagsToFilterBy.forEach((tagName) => {
        if (filterTags.includes(tagName)) {
          filteredSubscribers = filteredSubscribers.filter((subscriber) =>
            this.getSubscriberTags(subscriber).find((tag) => tag.text === tagName)
          );
        }
      });

      return filteredSubscribers;
    },
    selectedSubscribers() {
      return this.computedSubscribers.filter((subscriber) =>
        this.selectedSubscribersArray.includes(subscriber.id)
      );
    },
    selectAllCheckboxLabel() {
      if (this.selectedSubscribersArray.length === this.computedSubscribers.length) {
        return "All selected";
      }

      if (this.selectedSubscribersArray.length > 0) {
        return `${this.selectedSubscribersArray.length} selected`;
      }

      return "Select All";
    },
    selectAllCheckboxState() {
      return this.selectedSubscribersArray.length > 0;
    },
  },
  methods: {
    handleSubscriberSelect(id, value) {
      value
        ? this.selectedSubscribersArray.push(id)
        : (this.selectedSubscribersArray = this.selectedSubscribersArray.filter(
            (arrayId) => arrayId !== id
          ));
    },
    toggleFilterTag(tag) {
      if (this.filters.tags.includes(tag)) {
        this.filters.tags = this.filters.tags.filter((filter) => filter !== tag);
      } else {
        this.filters.tags.push(tag);
      }
    },
    getSubscriberTags(subscriber) {
      const customTags = [];

      function stateToText(state) {
        if (state.length === 0) return "Inactive";
        return state.charAt(0).toUpperCase() + state.slice(1);
      }

      function stateToColor(state) {
        switch (state) {
          case "active":
            return "green";
          case "paused":
            return "lightgrey";
          default:
            return "grey";
        }
      }

      // Active
      customTags.push({
        text: stateToText(subscriber?.club_specific?.state ?? ""),
        color: stateToColor(subscriber?.club_specific?.state ?? ""),
      });

      // Payment status
      if (subscriber?.subscription?.stripe_data?.payment_status !== "paid") {
        customTags.push({
          text: "Unpaid",
          color: "red",
        });
      }

      // Expiring soon
      const expires_at = subscriber?.subscription?.stripe_data?.expires_at;
      if (expires_at && moment(expires_at).diff(moment(), "days") < 14) {
        customTags.push({
          text: "Expiring soon",
          color: "orange",
        });
      }

      return [
        ...customTags,
        ...(subscriber?.club_specific?.tags?.map((tag) => ({ text: tag })) ?? []),
      ];
    },
    getSubscription(subscription) {
      const filteredSubscription =
        this.clubSubscriptions?.find((sub) => sub.id === subscription?.subscription_id) ?? null;

      if (filteredSubscription) {
        return filteredSubscription.name;
      } else {
        return "No paid subscription";
      }
    },
    showApplyModal() {
      this.isApplyModalVisible = true;
    },
    hideApplyModal() {
      this.isApplyModalVisible = false;
    },
    handleSelectAllCheckbox() {
      if (
        this.selectedSubscribersArray.length === 0 ||
        (this.selectedSubscribersArray.length > 0 &&
          this.selectedSubscribersArray.length !== this.computedSubscribers.length)
      ) {
        this.selectedSubscribersArray = this.computedSubscribers.map((subscriber) => subscriber.id);
      } else {
        this.selectedSubscribersArray = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.subscribers {
  max-width: 1280px;
  padding: 28px 40px;
  margin: 0 auto;
}

.subscribers-filter-box {
  margin-bottom: 19px;
  background: #ffffff;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 14px 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 34px;
}

.subscribers-filter-box-search {
  position: relative;
  flex: 0 0 415px;

  input {
    width: 100%;
    background: rgba(240, 241, 244, 0.8);
    border-radius: 6px;
    border: 1px solid rgba(240, 241, 244, 0.8);
    padding: 7px 40px 7px 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #323c47;
    line-height: 18px;
    letter-spacing: 0.2px;
    outline: none;
    height: initial;
  }

  input::placeholder {
    color: #a6aab4;
  }

  svg {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.subscribers-filter-box-tabs {
  flex: 1;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 34px;
}

.subscribers-filter-box-tabs-item {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-decoration: underline;
  color: #a6aab4;
  cursor: pointer;
  transition: color 0.2s ease;

  &.is-active,
  &:hover {
    color: #0ba3a9;
  }
}

.subscribers-filter-box-button {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #0ba3a9;
  border-radius: 6px;
  padding: 4px 24px;
  cursor: pointer;
}

.subscribers-table {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.06);
}

.subscribers-table-header {
  padding: 14px 24px;
  border-bottom: 1px solid #ebeff2;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.subscribers-table-apply {
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-decoration: underline;
  color: #0ba3a9;
}

.subscribers-table-apply.disabled {
  pointer-events: none;
  color: #a6aab4;
}

.subscribers-table-checkbox {
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.subscribers-table-checkbox span {
  margin-left: 24px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #334d6e;

  &.clear-all-text {
    color: #a6aab4;
    font-size: 12px;
    margin-left: 16px;
    transition: color 0.2s ease, border-color 0.2s ease;
    border: 1px solid #e8ebf1;
    padding: 0 5px;
    border-radius: 5px;

    &:hover {
      color: #0ba3a9;
      border-color: #c2e0e1;
    }
  }
}

.subscribers-table-checkbox input {
  height: initial;
}

.subscribers-table-body {
}

.subscribers-table-row {
  border-bottom: 1px solid #ebeff2;
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 22px 24px;
  position: relative;
  transition: background 0.2s ease;
  will-change: background;
}

.subscribers-table-row--selected {
  background: #f2f9ff;
}

.subscribers-table-row:last-child {
  border-color: transparent;
}

.subscribers-table-row-select {
  margin-right: 36px;
}

.subscribers-table-row-select input {
  cursor: pointer;
  height: initial;
}

.subscribers-table-row-name {
  flex: 0 0 175px;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #323c47;
}

.subscribers-table-row-name span {
  cursor: pointer;
}

.subscribers-table-row-name span:hover {
  text-decoration: underline;
}

.subscribers-table-row-email,
.subscribers-table-row-phone,
.subscribers-table-row-level,
.subscribers-table-row-date {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #707683;
}

.subscribers-table-row-email {
  flex: 0 0 175px;
}

.subscribers-table-row-phone {
  flex: 0 0 150px;
}

.subscribers-table-row-level {
  flex: 0 0 150px;
}

.subscribers-table-row-tags {
  flex: 1;
}

.subscribers-table-row-date {
  flex: 0 0 150px;
  text-align: right;
}

.subscribers-table-row-tags {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.subscribers-table-row-tags-item {
  padding: 2px 7px;
  border-radius: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #ffffff;
  background: #46aaf2;

  &.tag-color {
    &-orange {
      background: #ff7d00;
    }

    &-green {
      background: #54d3ad;
    }

    &-red {
      background: #f7685b;
    }

    &-grey {
      background: #d6d9e4;
      color: #757f8c;
    }

    &-lightgrey {
      background: #b7b9bf;
    }
  }
}
</style>
