<template>
  <div class="tab">
    <p class="tab-title">
      Tags make it easier to find subscribers when searching through your customer list. You can
      create new tags or apply existing tags below.
    </p>
    <p class="tab-clear-tags">
      <span @click="clearAllTags">Clear all tags</span>
    </p>
    <el-select
      v-model="formData.tags"
      multiple
      filterable
      allow-create
      default-first-option
      placeholder=" "
    />
    <div class="tab-button-wrapper">
      <div class="tab-button" @click="applyChange">Apply tags</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    subscribers: {
      type: Array,
      required: true,
      default: () => [],
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: ["Tag 1", "Tag 2", "Custom 1"],
    };
  },
  computed: {
    formData: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    clearAllTags() {
      this.formData.tags = [];
    },
    saveChange() {
      this.updateApplyAction({
        key: "tags",
        value: this.tags,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.tab-title {
  margin-bottom: 76px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.2px;
  color: #757f8c;
}

.tab-button-wrapper {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
}

.tab-button {
  min-width: 173px;
  padding: 14px 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #0ba3a9;
  border-radius: 6px;
  cursor: pointer;
}

.tab-clear-tags {
  cursor: pointer;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.2px;
  text-decoration: underline;
  color: #0ba3a9;
}

.tab {
  :deep(.el-select) {
    cursor: pointer;
  }

  :deep(.el-input__inner) {
    height: 224px !important;
  }

  :deep(.el-select__tags) {
    display: block;
    max-width: initial !important;
    height: 100%;
    padding: 14px;
  }

  :deep(.el-input__suffix) {
    display: none;
  }

  :deep(.el-tag) {
    height: initial;
    padding-top: 2px;
    padding-bottom: 2px;
    background: #0ba3a9;
    border-radius: 4px;
    margin-left: 0;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 0;
  }

  :deep(.el-select__input) {
    margin-left: 0;
    width: initial !important;
  }

  :deep(.el-input.is-focus .el-input__inner) {
    border-color: #0ba3a9;
  }

  :deep(.el-tag span) {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }

  :deep(.el-tag__close) {
    top: -2px !important;
    right: -4px !important;
    background: none !important;
  }

  :deep(.el-tag__close:before) {
    content: url("~@/assets/icons/tag-remove.svg");
  }
}
</style>
