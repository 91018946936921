<template>
  <div class="apply-action">
    <div class="apply-action-background" @click="closeModal" />
    <div class="apply-action-container" v-loading="isLoading">
      <div class="apply-action-close" @click="closeModal">
        <inline-svg :src="require('@/assets/icons/close.svg')" aria-label="Close modal" />
      </div>
      <div class="apply-action-body">
        <div class="apply-action-left">
          <div class="apply-action-title">Apply an action</div>
          <div class="apply-action-tabs">
            <div
              v-for="(link, index) in navigation"
              :key="`navigation-link-${index}`"
              class="apply-action-tabs-item"
              :class="{ active: isActive(link.tab) }"
            >
              <div class="apply-action-tabs-item-button" @click="activeTab(link.tab)">
                {{ link.label }}
              </div>
            </div>
          </div>
        </div>

        <div class="apply-action-right">
          <component
            v-model="action"
            :is="renderActiveTab"
            :subscribers="selectedSubscribers"
            :ids="computedSubscribersIds"
            @handleClose="closeModal"
            @handleLoading="handleLoading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InlineSvg from "vue-inline-svg";
import SendMessage from "@/components/ApplyAction/SendMessage.vue";
import RequestPayment from "@/components/ApplyAction/RequestPayment.vue";
import CreditRefund from "@/components/ApplyAction/CreditRefund.vue";
import ChangeActive from "@/components/ApplyAction/ChangeActive.vue";
import ModifyTags from "@/components/ApplyAction/ModifyTags.vue";
import ExportCsv from "@/components/ApplyAction/ExportCsv.vue";

export default {
  components: {
    InlineSvg,
    SendMessage,
    RequestPayment,
    CreditRefund,
    ChangeActive,
    ModifyTags,
    ExportCsv,
  },
  props: {
    selectedSubscribers: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      currentTab: "message",
      navigation: [
        { label: "Send an SMS message", tab: "message" },
        { label: "Request payment", tab: "payment" },
        { label: "Apply a credit / refund", tab: "credit" },
        { label: "Set active / inactive", tab: "active" },
        // { label: 'Add / remove tags', tab: 'tags' },
        { label: "Export CSV", tab: "csv" },
      ],
      action: {
        message: "",
        payment: {
          price: null,
          message: "",
        },
        changeAmount: {
          price: null,
          option: "Refund this amount",
        },
        changeActive: {
          isActive: true,
          option: "Refund unused credits",
          isTermination: false,
        },
        tags: null,
      },
    };
  },
  computed: {
    computedSubscribersIds() {
      return this.selectedSubscribers.map((customer) => customer.id);
    },
    renderActiveTab() {
      if (this.isActive("message")) {
        return "SendMessage";
      }

      if (this.isActive("payment")) {
        return "RequestPayment";
      }

      if (this.isActive("credit")) {
        return "CreditRefund";
      }

      if (this.isActive("active")) {
        return "ChangeActive";
      }

      if (this.isActive("tags")) {
        return "ModifyTags";
      }

      if (this.isActive("csv")) {
        return "ExportCsv";
      }

      return "SendMessage";
    },
  },
  methods: {
    handleLoading(payload) {
      this.isLoading = payload;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    isActive(currentTab) {
      return this.currentTab === currentTab;
    },
    activeTab(currentTab) {
      this.currentTab = currentTab;
    },
  },
  mounted() {
    console.log("Checked subscribers", this.selectedSubscribers);
    console.log("Club", this.$store.getters.club);
  },
};
</script>

<style lang="scss" scoped>
.apply-action {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: scroll;
  padding: 135px 0;
}

.apply-action-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #171d33;
  opacity: 0.8;
}

.apply-action-close {
  position: absolute;
  top: -64px;
  cursor: pointer;
  right: 0;
}

.apply-action-container {
  z-index: 1;
  position: relative;
  width: 1110px;
  min-height: 630px;
  margin: 0 auto;
  background: #fff;
  border-radius: 6px;
}

.apply-action-body {
  display: flex;
  height: 100%;
}

.apply-action-left {
  padding: 32px 24px;
  background: #0ba3a9;
  flex: 0 0 445px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.apply-action-right {
  flex: 1;
  background: #ffffff;
  padding: 60px 54px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  overflow: auto;
}

.apply-action-title {
  margin-bottom: 52px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 42px;
  letter-spacing: 0.5px;
  color: #ffffff;
}

.apply-action-tabs {
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
}

.apply-action-tabs-item {
  border: 1px solid #ffffff;
  border-radius: 6px;
  position: relative;
  text-align: center;
}

.apply-action-tabs-item-button {
  font-style: normal;
  width: 100%;
  padding: 14px 20px;
  height: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #ffffff;
  outline: none;
}

.apply-action-tabs-item.active {
  background: #fff;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.apply-action-tabs-item.active:before {
  content: "";
  position: absolute;
  left: 100%;
  width: 25px;
  height: 54px;
  background: #fff;
  top: -1px;
}

.apply-action-tabs-item.active .apply-action-tabs-item-button {
  color: #0ba3a9;
}
</style>
