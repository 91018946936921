<template>
  <div class="tab">
    <p class="tab-title">
      Downloading your subscriber records into a XLSX file is a simple way to share your Klubba
      subscriber records with other software systems.
    </p>
    <div class="tab-logo">
      <img src="@/assets/icons/excel-icon.png" />
    </div>
    <div class="tab-button-wrapper">
      <div class="tab-button" @click="downloadCsv">Download XLSX</div>
    </div>
  </div>
</template>

<script>
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";

export default {
  props: {
    subscribers: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    async downloadCsv() {
      const workbook = new Excel.Workbook();
      const worksheet = workbook.addWorksheet("Subscribers Sheet");

      worksheet.columns = [
        { header: "First name", key: "first_name", width: 25 },
        { header: "Last Name", key: "last_name", width: 25 },
        { header: "Email", key: "email", width: 25 },
        { header: "Phone number", key: "phone_number", width: 25 },
        { header: "Gender", key: "gender", width: 25 },
        { header: "Date of birth", key: "date_of_birth", width: 25 },
        { header: "Notifications", key: "notifications", width: 25 },
        { header: "Questions", key: "questions", width: 25 },
      ];

      this.subscribers.forEach((subscriber) => {
        worksheet.addRow({
          first_name: subscriber.first_name,
          last_name: subscriber.last_name,
          email: subscriber.email,
          phone_number: subscriber.phone_number,
          gender: subscriber.gender,
          date_of_birth: moment(subscriber.date_of_birth).format("YYYY-MM-DD"),
          notifications: subscriber.club_specific.notifications.join(", "),
          questions: subscriber.club_specific.questions
            .map((question) => question.label + ": " + question.value)
            .join("\n"),
        });
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const fileExtension = ".xlsx";

      const blob = new Blob([buffer], { type: fileType });

      saveAs(blob, "Subscribers" + fileExtension);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.tab-title {
  flex: 1;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.2px;
  color: #757f8c;
}

.tab-button-wrapper {
  margin-top: 24px;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
}

.tab-button {
  min-width: 173px;
  padding: 14px 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: #0ba3a9;
  border-radius: 6px;
  cursor: pointer;
}

.tab-logo {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}
</style>
