<template>
  <div class="tab">
    <p class="tab-title">
      You can send a message to your subscribers using a combination of<br />
      your own message and the below automatic substitutions.
    </p>
    <div class="tab-tags">
      <span v-for="(tag, tagIndex) in smartTags" :key="tag">
        <span @click="message += tag" class="smart-tag">{{ tag }}</span>
        <span>{{ tagIndex !== smartTags.length - 1 ? ", " : "" }}</span>
      </span>
    </div>
    <div class="tab-textarea">
      <textarea v-model="message" :maxlength="maxLength" placeholder="Type over this" />
      <span>Message</span>
      <div class="tab-textarea-count">{{ message.length }} / {{ maxLength }}</div>
    </div>
    <div v-if="formattedMessageExample.length > 0" class="result">
      <div class="message-example-title">Message example:</div>
      <div class="message-example">{{ formattedMessageExample }}</div>
    </div>
    <div class="tab-button-wrapper">
      <BaseButton :disabled="message.length < 10" @click="sendNow">Send now</BaseButton>
    </div>

    <!-- Too many messages -->
    <div class="too-much" v-if="subscribers.length > 20">
      <div class="title">You can send only up to 20 SMS at once</div>
      <div class="text">
        If you want to send more, please export your customers<br />
        and use another service
      </div>
    </div>
  </div>
</template>

<script>
import { ReplaceSmartTagsWithContent } from "./smartTags";
import firebase from "firebase";
import firebaseApp from "@/config/firebase";
import { mapGetters } from "vuex";
import BaseButton from "../BaseButton";
import { useToast } from "vue-toastification";

export default {
  components: { BaseButton },
  props: {
    subscribers: {
      type: Array,
      required: true,
      default: () => [],
    },
    modelValue: {
      type: Object,
      required: true,
    },
  },
  data() {
    const toast = useToast();
    return {
      toast: toast,
      message: "",
      maxLength: 160,
      smartTags: [
        "[KLUBBA_URL]",
        "[SUBSCRIBER_FIRSTNAME]",
        "[SUBSCRIBER_PRODUCT]",
        "[SUBSCRIPTION_EXPIRY_DATE]",
        "[SUBSCRIPTION_AMOUNT]",
        "[CLUB_NAME]",
        "[DAYS_UNTIL_EXPIRY]",
      ],
    };
  },
  computed: {
    ...mapGetters(["clubSubscriptionById", "clubId"]),
    formattedMessageExample() {
      return this.message.length > 0
        ? ReplaceSmartTagsWithContent(this, this.subscribers[0], this.message)
        : "";
    },
    messages() {
      return this.subscribers.map((subscriber) => {
        const subscriptionId = subscriber?.subscription?.stripe_data?.subscription_id ?? "";

        return {
          datestamp: firebase.firestore.Timestamp.now().seconds * 1000,
          subscriber_id: subscriber.id,
          subscriber_firstname: subscriber?.first_name ?? "",
          subscriber_lastname: subscriber?.last_name ?? "",
          subscriber_phone: subscriber?.phone_number ?? "",
          subscriber_status: subscriber?.club_specific?.state ?? "inactive",
          subscription_id: subscriptionId,
          subscription_title: subscriber?.subscription?.product_name ?? "", // TODO: Add subscription title there
          subscription_fee: subscriber?.subscription?.price
            ? Number(subscriber?.subscription?.price)
            : "",
          subscription_expiry: subscriber?.subscription?.period?.end ?? "",
          message_status: "pending",
          message: ReplaceSmartTagsWithContent(this, subscriber, this.message),
        };
      });
    },
  },
  methods: {
    async sendNow() {
      this.$emit("handleLoading", true);

      console.log("Sending messages: ", this.messages);

      const sendSMS = await firebaseApp.functions().httpsCallable("sendSMS");
      await sendSMS({
        club_id: this.clubId,
        messages: this.messages,
      })
        .then((result) => {
          console.log(result);
          this.message = "";
          this.$emit("handleLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.toast.error(error.message);
          this.$emit("handleLoading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
  position: relative;
}

.too-much {
  position: absolute;
  left: -10px;
  top: -10px;
  right: -10px;
  bottom: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(white, 0.8);
  text-align: center;
  backdrop-filter: blur(2px);

  .title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 1.5;
    font-weight: 600;
    color: #3ea3a9;
  }

  .text {
    font-size: 16px;
    line-height: 1.6;
  }
}

.tab-title {
  margin-bottom: 34px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.2px;
  color: #757f8c;
}

.tab-tags {
  margin-bottom: 44px;
  padding: 22px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: rgba(17, 17, 17, 0.48);
  background: #f0f1f4;
  border-radius: 8px;
}

.tab-textarea textarea {
  width: 100%;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #323c47;
  border: 1px solid #a6aab4;
  border-radius: 6px;
  padding: 16px 20px;
  outline: none;
  resize: none;
}

.tab-textarea textarea:focus {
  border-color: #0ba3a9;
}

.tab-textarea textarea:focus + span {
  color: #0ba3a9;
}

.tab-textarea textarea::placeholder {
  color: #a6aab4;
}

.tab-textarea {
  position: relative;
}

.tab-textarea textarea {
  margin-bottom: 6px;
  min-height: 128px;
}

.tab-textarea span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #a6aab4;
  position: absolute;
  top: -10px;
  background: #fff;
  padding: 0 8px;
  left: 14px;
}

.tab-textarea-count {
  margin-bottom: 37px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.2px;
  color: rgba(17, 17, 17, 0.48);
}

.tab-button-wrapper {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
}

.result {
  margin-bottom: 40px;
}

.message-example-title {
  font-size: 12px;
  margin-bottom: 12px;
  color: #919397;
}

.message-example {
  padding: 10px 15px;
  border-radius: 5px;
  line-height: 1.5;
  font-size: 14px;
  background: #f0f1f4;
}

.smart-tag {
  transition: color 0.2s ease;
  cursor: pointer;

  &:hover {
    color: #0ba3a9;
  }
}
</style>
