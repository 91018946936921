<template>
  <div class="tab">
    <p class="tab-title">
      You can send a message to your subscribers using a combination of your own message and the
      below automatic substitutions.
    </p>
    <div class="coming-soon">This feature will be available in a future release</div>
    <div class="tab-number">
      <inline-svg :src="require('@/assets/icons/dollar.svg')" />
      <input disabled type="number" v-model="price" placeholder="100" />
      <span>Price</span>
    </div>
    <div class="tab-show-message" @click="showTags = !showTags">
      <span>{{ showTags ? "Hide" : "Show" }} message substitutions</span>
    </div>
    <div v-if="showTags" class="tab-tags">
      <span v-for="(tag, tagIndex) in smartTags" :key="tag">
        <span @click="message += tag" class="smart-tag">{{ tag }}</span>
        <span>{{ tagIndex !== smartTags.length - 1 ? ", " : "" }}</span>
      </span>
    </div>
    <div class="tab-textarea">
      <textarea disabled v-model="message" :maxlength="maxLength" placeholder="Type over this" />
      <span>Message</span>
      <div class="tab-textarea-count">{{ message.length }} / {{ maxLength }}</div>
    </div>
    <div v-if="formattedMessageExample.length > 0" class="result">
      <div class="message-example-title">Message example:</div>
      <div class="message-example">{{ formattedMessageExample }}</div>
    </div>
    <div class="tab-button-wrapper">
      <BaseButton
        :disabled="true /* message.length === 0 || !price || price < 1 */"
        @click="applyChange"
      >
        Apply changes
      </BaseButton>
    </div>
  </div>
</template>

<script>
import firebaseApp from "@/config/firebase";
import InlineSvg from "vue-inline-svg";
import BaseButton from "../BaseButton";
import { ReplaceSmartTagsWithContent } from "./smartTags";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import firebase from "firebase";

export default {
  components: {
    BaseButton,
    InlineSvg,
  },
  props: {
    subscribers: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    const toast = useToast();
    return {
      toast: toast,
      maxLength: 160,
      price: 0,
      message: "",
      showTags: false,
      smartTags: [
        "[KLUBBA_URL]",
        "[SUBSCRIBER_FIRSTNAME]",
        "[SUBSCRIBER_PRODUCT]",
        "[SUBSCRIPTION_EXPIRY_DATE]",
        "[SUBSCRIPTION_AMOUNT]",
        "[CLUB_NAME]",
        "[DAYS_UNTIL_EXPIRY]",
      ],
    };
  },

  computed: {
    ...mapGetters(["clubSubscriptionById"]),
    messages() {
      return this.subscribers.map((subscriber) => {
        const subscriptionId = subscriber?.subscription?.stripe_data?.subscription_id ?? "";

        return {
          datestamp: firebase.firestore.Timestamp.now().seconds * 1000,
          subscriber_id: subscriber.id,
          subscriber_firstname: subscriber?.first_name ?? "",
          subscriber_lastname: subscriber?.last_name ?? "",
          subscriber_phone: subscriber?.phone_number ?? "",
          subscriber_status: subscriber?.club_specific?.state ?? "inactive",
          subscription_id: subscriptionId,
          subscription_title: subscriber?.subscription?.product_name ?? "", // TODO: Add subscription title there
          subscription_fee: subscriber?.subscription?.price
            ? Number(subscriber?.subscription?.price)
            : "",
          subscription_expiry: subscriber?.subscription?.period?.end ?? "",
          message_status: "pending",
          message: ReplaceSmartTagsWithContent(this, subscriber, this.message),
        };
      });
    },
    formattedMessageExample() {
      return this.message.length > 0
        ? ReplaceSmartTagsWithContent(this, this.subscribers[0], this.message)
        : "";
    },
  },
  methods: {
    async applyChange() {
      this.$emit("handleLoading", true);

      console.log("Sending messages: ", this.messages);

      const data = {
        price: this.price,
        messages: this.messages,
      };

      const requestPayment = await firebaseApp.functions().httpsCallable("requestPayment");

      await requestPayment(data)
        .then((result) => {
          console.log(result);
          this.form.amount = 0;
          this.$emit("handleLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.toast.error(error.message);
          this.$emit("handleLoading", false);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100%;
}

.tab-title {
  margin-bottom: 76px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.2px;
  color: #757f8c;
}

.tab-tags {
  margin-top: 12px;
  margin-bottom: 44px;
  padding: 22px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: rgba(17, 17, 17, 0.48);
  background: #f0f1f4;
  border-radius: 8px;
}

.tab-number input,
.tab-textarea textarea {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #323c47;
  border: 1px solid #a6aab4;
  border-radius: 6px;
  padding: 16px 20px;
  outline: none;
  resize: none;
}

.tab-number input:focus,
.tab-textarea textarea:focus {
  border-color: #0ba3a9;
}

.tab-number input:focus + span,
.tab-textarea textarea:focus + span {
  color: #0ba3a9;
}

.tab-number input::placeholder,
.tab-textarea textarea::placeholder {
  color: #a6aab4;
}

.tab-number,
.tab-textarea {
  position: relative;
}

.tab-number {
  margin-bottom: 8px;
}

.tab-number svg {
  position: absolute;
  left: 21px;
  top: 20px;
}

.tab-textarea textarea {
  margin-bottom: 6px;
  min-height: 128px;
}

.tab-number span,
.tab-textarea span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #a6aab4;
  position: absolute;
  top: -10px;
  background: #fff;
  padding: 0 8px;
  left: 14px;
}

.tab-textarea-count {
  margin-bottom: 37px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.2px;
  color: rgba(17, 17, 17, 0.48);
}

.tab-number input {
  padding-left: 38px;
}

.tab-number input::-webkit-inner-spin-button {
  display: none;
}

.tab-button-wrapper {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
}

.tab-show-message {
  cursor: pointer;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.2px;
  text-decoration: underline;
  color: #0ba3a9;
}
.result {
  margin-bottom: 40px;
  margin-top: -20px;
}

.message-example-title {
  font-size: 12px;
  margin-bottom: 12px;
  color: #919397;
}

.message-example {
  padding: 10px 15px;
  border-radius: 5px;
  line-height: 1.5;
  font-size: 14px;
  background: #f0f1f4;
}

.coming-soon {
  text-align: center;
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  margin: -40px 0 50px;
  padding: 10px;
  background-color: #fae9db;
  color: orange !important;
}

.smart-tag {
  transition: color 0.2s ease;
  cursor: pointer;

  &:hover {
    color: #0ba3a9;
  }
}
</style>
