<template>
  <div class="tab">
    <p class="tab-title">
      You can apply a credit or refund to a subscriber using the below. This may be useful when
      providing promotional accounts or managing payment anomalies.
    </p>
    <div class="coming-soon">This feature will be available in a future release</div>
    <div class="tab-number">
      <inline-svg :src="require('@/assets/icons/dollar.svg')" />
      <input disabled type="number" v-model="form.amount" placeholder="100" />
      <span>Price</span>
    </div>
    <div class="tab-radio">
      <label v-for="(option, index) in options" :key="`option-${index}`" class="tab-radio-input">
        <input disabled type="radio" :value="option.value" v-model="form.action_type" />
        <div class="tab-radio-slider" />
        <span>{{ option.text }}</span>
      </label>
    </div>
    <div class="tab-divider" />
    <div class="tab-button-wrapper">
      <BaseButton :disabled="true /* !form.amount || form.amount < 1 */" @click="applyChange">
        Apply changes
      </BaseButton>
    </div>
  </div>
</template>

<script>
import firebaseApp from "@/config/firebase";
import InlineSvg from "vue-inline-svg";
import BaseButton from "../BaseButton";
import { useToast } from "vue-toastification";

export default {
  components: {
    BaseButton,
    InlineSvg,
  },
  props: {
    subscribers: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    const toast = useToast();
    return {
      toast: toast,
      options: [
        {
          text: "Refund this amount",
          value: "refund",
        },
        {
          text: "Credit this amount",
          value: "credit",
        },
      ],
      form: {
        amount: 0,
        action_type: "refund",
      },
    };
  },
  computed: {
    userIds() {
      return this.subscribers.map((subscriber) => subscriber.id);
    },
  },
  methods: {
    async applyChange() {
      this.$emit("handleLoading", true);

      const data = {
        action: this.form,
        users: this.userIds,
      };

      const applyCreditOrRefund = await firebaseApp
        .functions()
        .httpsCallable("applyCreditOrRefund");

      await applyCreditOrRefund(data)
        .then((result) => {
          console.log(result);
          this.form.amount = 0;
          this.$emit("handleLoading", false);
        })
        .catch((error) => {
          console.log(error);
          this.toast.error(error.message);
          this.$emit("handleLoading", false);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.tab {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

.tab-title {
  margin-bottom: 76px;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 1.2px;
  color: #757f8c;
}

.tab-tags {
  margin-bottom: 44px;
  padding: 22px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: rgba(17, 17, 17, 0.48);
  background: #f0f1f4;
  border-radius: 8px;
}

.tab-number input {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #323c47;
  border: 1px solid #a6aab4;
  border-radius: 6px;
  padding: 16px 20px;
  outline: none;
  resize: none;
}

.tab-number input:focus {
  border-color: #0ba3a9;
}

.tab-number input:focus + span {
  color: #0ba3a9;
}

.tab-number input::placeholder {
  color: #a6aab4;
}

.tab-number {
  position: relative;
}

.tab-number {
  margin-bottom: 8px;
}

.tab-number svg {
  position: absolute;
  left: 21px;
  top: 20px;
}

.tab-number span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #a6aab4;
  position: absolute;
  top: -10px;
  background: #fff;
  padding: 0 8px;
  left: 14px;
}

.tab-number input {
  padding-left: 38px;
}

.tab-number input::-webkit-inner-spin-button {
  display: none;
}

.tab-button-wrapper {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-end;
}

.tab-radio {
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 34px;
  display: flex;
  flex-flow: column nowrap;
  gap: 34px;
}

.tab-radio-input {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.tab-radio-input span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #111111;
  opacity: 0.48;
}

.tab-radio-slider {
  margin-right: 49px;
  transition: 0.4s;
  display: block;
  background: #f0f1f4;
  width: 24px;
  height: 24px;
  border-radius: 100px;
  position: relative;
}

.tab-radio-slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f1f4;
  border-radius: 50%;
  transition: 0.4s;
}

.tab-radio-input input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.tab-radio-input input:checked + .tab-radio-slider {
  background-color: #0ba3a9;
}

.tab-radio-input input:focus + .tab-radio-slider {
  box-shadow: 0 0 1px #0ba3a9;
}

.tab-radio-input input:checked + .tab-radio-slider:before {
  background-color: #fff;
}

.tab-divider {
  background: #e8e9ec;
  height: 1px;
  width: 100%;
  margin-bottom: 71px;
}

.coming-soon {
  text-align: center;
  display: inline-block;
  font-weight: 500;
  font-size: 12px;
  margin: -40px 0 50px;
  padding: 10px;
  background-color: #fae9db;
  color: orange !important;
}
</style>
